
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import TeacherList from './cpns/teacher-list.vue'
import CourseAll from '../course/cpns/course-all.vue'
import Breadcrumb from '@/components/breadcrumb'
import MarkdownIt from '@jiker/markdown-it/MarkdownIt.vue'
import hhRequest from '@/service'

export default defineComponent({
  components: {
    Breadcrumb,
    TeacherList,
    CourseAll,
    MarkdownIt
  },
  async setup() {
    const route = useRoute()
    const store = useStore()

    // 网络请求
    // store.dispatch('partners/getTeacherRequestAction', {
    //   url: `/network/${route.params.id}`
    // })
    //
    // const teacher = computed(() => store.getters[`partners/getTeacherData`])
    // console.log('hh======teacher', teacher)
    const network = await hhRequest.get({ url: `/network/${route.params.id}` })
    return {
      network
    }
  }
})
