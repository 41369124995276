
import { Prop } from 'vue'
import { Teacher } from '@/store/partners/types'
import TeacherCard from './teacher-card.vue'
import Empty from '@/components/empty'

export default {
  name: 'TeacherList',
  components: { Empty, TeacherCard },
  props: {
    teachers: Array as Prop<Teacher[]>
  }
}
