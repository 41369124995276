import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-c470f1e4")
const _hoisted_1 = { class: "teacher-list-container" }
const _hoisted_2 = {
  key: 0,
  class: "teacher-list"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_teacher_card = _resolveComponent("teacher-card")!
  const _component_empty = _resolveComponent("empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.teachers && $props.teachers.length > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.teachers, (teacher) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "teacher-item",
              key: teacher
            }, [
              _createVNode(_component_teacher_card, { teacher: teacher }, null, 8, ["teacher"])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(_component_empty, { key: 1 }))
  ]))
}