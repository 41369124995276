
import { defineComponent, PropType } from 'vue'
import { Teacher } from '@/store/partners/types'

export default defineComponent({
  name: 'TeacherCard',
  props: {
    teacher: {
      type: Object as PropType<Teacher>,
      required: true
    }
  }
})
